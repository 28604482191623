@import '~antd/dist/antd.css';

.App {
  font-family: sans-serif;
  text-align: center;
}

html,
body,
#root,
.App {
  height: 100%;
}
